








import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { ApiError, ApiErrorType } from '@/api/data';
import { BreadcrumbKey } from '@/models/site.model';
import NotLinked from '@/components/pages/NotLinked.vue';

type Data = {};
type Computed = { messages: string[]; notLinkedError: boolean };
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Errors',
  components: { RplIcon, NotLinked },
  computed: {
    messages() {
      return Array.from(
        new Set<string>(
          this.$store.getters.apiErrors.map((error: ApiError) => error.message),
        ),
      );
    },
    notLinkedError() {
      return this.$store.getters.apiErrors.some(
        (error: ApiError) => error.type === ApiErrorType.UserNotLinked,
      );
    },
  },
  created() {
    if (this.messages.length) {
      let headerText;
      if (
        this.$store.getters.apiErrors.every(
          (error: ApiError) => error.type === ApiErrorType.TenantAccess,
        )
      ) {
        headerText = 'Access denied';
      } else if (!this.notLinkedError) {
        headerText = 'Error';
      }
      if (headerText) {
        this.$store.commit('setHeaderText', headerText);
        this.$store.commit('addBreadcrumbData', {
          key: BreadcrumbKey.Error,
          breadcrumbs: {
            text: headerText,
          },
        });
      }
    } else {
      this.$router.push({ name: 'root' });
    }
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
});
